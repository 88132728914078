<template lang="pug">
.fgpc(:style="{ marginTop: $store.state.navbarHeight + 'px' }")
  v-container.d-flex.align-center.justify-center.w-100
    v-row.justify-center.mt-5(style="flex-shrink: 1; flex-grow: 1")
      v-col.d-flex.justify-center.align-center(cols="12")
        h1.text-center.login-title 重新設定密碼
      v-col(cols="10", md="5")
        form.form-login(method="post", style="margin-top: 40px")
        .input_container.mt-6
          label.sr-only(for="id_password") 設定新密碼
          input#id_password(
            required,
            type="password",
            name="password",
            placeholder="設定新密碼",
            v-model="password"
          )
          svg-icon(iconClass="key_hollow", className="key_hollow")
        .input_container.mt-6
          label.sr-only(for="id_password_repeat") 再次輸入密碼
          input#id_password_repeat(
            required,
            type="password",
            name="password_repeat",
            placeholder="再次輸入密碼",
            v-model="passwordRepeat"
          )
          svg-icon(iconClass="key_hollow", className="key_hollow")
        .btn.sign_up.w-100(style="margin-top: 36px", @click="confirmPassword") 重設密碼
      v-col.d-flex.justify-center.align-center(cols="12")
        v-footer(align="center")
          .footer
            h5 Copyright © 2022 競晰股份有限公司
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-error(
    :title="dialogErrorData.title",
    @closeErrorDialog="closeErrorDialog",
    :dialogError="dialogErrorShow"
  )
    template(#content)
      p {{ dialogErrorData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
</template>

<script>
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogLoading from "@/components/dialog/DialogLoading";
import { resetPasswordConfirm } from "@/api/user";
import { validStringEmpty } from "@/utils/validate";

export default {
  name: "ForgetPasswordConfirm",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    DialogSuccess,
    DialogError,
    DialogLoading,
  },
  data() {
    return {
      password: "",
      passwordRepeat: "",
      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogErrorShow: false,
      dialogErrorData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "忘記密碼確認 | Game Changer",
        description: "忘記密碼確認",
        keywords: "Game changer,籃球,會員,忘記密碼確認",
        url: "https://www.gamechanger.tw/reset_password",
        image: "",
        smoType: "website",
      },
    };
  },
  created() {
    if (!this.$route.query.user || !this.$route.query.token) {
      this.$router.push({ name: "Home" });
    }
  },
  methods: {
    confirmPassword() {
      if (
        validStringEmpty(this.password) ||
        validStringEmpty(this.passwordRepeat)
      ) {
        this.showErrorDialog("資料錯誤", "請輸入密碼");
        return;
      }
      if (this.password !== this.passwordRepeat) {
        this.showErrorDialog("資料錯誤", "請確認密碼及再次確認是否相同");
        return;
      }
      this.showLoadingDialog("密碼重設中...");
      let inputData = {
        uid: this.$route.query.user,
        token: this.$route.query.token,
        new_password: this.password,
      };
      resetPasswordConfirm(inputData)
        .then(() => {
          this.closeLoadingDialog();
          this.resetInputData();
          this.showSuccessDialog("密碼設定成功", "請重新登入會員");
          setTimeout(() => {
            this.closeSuccessDialog();
            this.$router.push({ name: "Login" });
          }, 1500);
        })
        .catch((error) => {
          this.closeLoadingDialog();
          this.showErrorDialog("密碼設定失敗", error.response.data.error_msg);
        });
    },
    resetInputData() {
      this.password = "";
      this.passwordRepeat = "";
    },
    showErrorDialog(title, text) {
      this.dialogErrorData.title = title;
      this.dialogErrorData.text = text;
      this.dialogErrorShow = true;
    },
    closeErrorDialog() {
      this.dialogErrorData.title = "";
      this.dialogErrorData.text = "";
      this.dialogErrorShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/user/forget_password";
</style>
